import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

// Context
import UserContext from '../../state-management/context/UserContext';
import {
    INCREMENT_INDEX,
    INCREMENT_SKIP_PAGE,
    SET_LOADING,
    SET_NAVIGATION_BLOCK,
    SET_SESSION_EXPIRED,
    UPDATE_USER,
    SET_PROMPTS,
    UPDATE_DOCUMENTS
} from '../../state-management/actions/actionTypes';

// Components
import Button from '../shared/Button';
import InputText from '../shared/InputText';
import InputDropdown from '../shared/InputDropdown';

// Consts
import api from '../../consts/api';

const BiometricsCapture = () => {
    // Context variables
    const [userState, userDispatch] = useContext(UserContext);

    // Translation hook
    const { t } = useTranslation();

    // State variables
    const [firstNames, setFirstNames] = useState('');
    const [firstNamesError, setFirstNamesError] = useState('');
    const [lastName, setLastName] = useState('');
    const [lastNameError, setLastNameError] = useState('');
    const [selectedCitizenship, setSelectedCitizenship] = useState('');
    const [updateError, setUpdateError] = useState('');

    // Ensure the new hire is at the top of the page
    useEffect(() => window.scroll(0, 0), [])

    useEffect(() => {
        if (firstNames || lastName || selectedCitizenship) {
            if (!userState.isNavigationBlocked) {
                userDispatch({
                    type: SET_NAVIGATION_BLOCK,
                    isNavigationBlocked: true,
                });
            }
        } else if (!firstNames && !lastName && !selectedCitizenship) {
            if (userState.isNavigationBlocked) {
                userDispatch({
                    type: SET_NAVIGATION_BLOCK,
                    isNavigationBlocked: false,
                });
            }
        }
        // eslint-disable-next-line
    }, [firstNames, lastName, selectedCitizenship]);

    const handleFirstNameInput = (value) => {
        setFirstNames(value);

        if (/\d/.test(value.trim())) {
            setFirstNamesError(t('errors.invalid-character'));
        } else {
            setFirstNamesError('');
        }
    };

    const handleLastNameInput = (value) => {
        setLastName(value);

        if (/\d/.test(value.trim())) {
            setLastNameError('errors.invalid-character');
        } else {
            setLastNameError('');
        }
    }

    /**
     * Check the validity of the form
     */
    const isFormValid = () => {
        if (!firstNames || firstNamesError) {
            return false;
        }

        if (!lastName || lastNameError) {
            return false;
        }

        if (!selectedCitizenship) {
            return false;
        }

        return true;
    };

    /**
     * Send a patch request with the updated new hire details
     */
    const handleFormSubmit = async () => {
        userDispatch({ type: SET_LOADING, isLoading: true });
        const selectedCountry = userState.countries.filter((country) => country.name === selectedCitizenship)[0];
    
        const uuid = userState.uuid;
        const language = userState.currentLanguage;
    
        try {
            const response = await api.patch('/new-hires', {
                firstName: firstNames,
                lastName,
                citizenship: selectedCountry,
            });

        //    const user = {firstName: firstNames, lastName: lastName}


           //this needs to be checked with sf, I think the sf already gets the token, but in order to make it work on local we need to use the sandbox token 

            const sdk = await api.post(`/vendors/webhooks/get-sdk-token`, {
                applicantId: response.data.vendor.id,
               })
    
            userDispatch({
                type: UPDATE_USER,
                user: {
                    firstName: response.data.firstName,
                    lastName: response.data.lastName,
                    countryOfHire: userState.user.countryOfHire,
                    isoCountryOfHire: userState.user.isoCountryOfHire,
                    homeCountry: response.data.citizenship,
                },
                //this needs to be checked with sf to see if the vendor data that comes from sf its valid
                // vendor: response.data.vendor,
                vendor: sdk.data,
                isLoading: true,
            });
    
            const promptsResponse = await api.post('/new-hires/prompts', { uuid, language });
            if (promptsResponse.data.length === 0 || JSON.stringify(promptsResponse.data[0]) === '{}') {
                const documentResponse = await api.post('/new-hires/get-document-combination', { prompts: [] });
                userDispatch({
                    type: UPDATE_DOCUMENTS,
                    documentCombinations: {
                        isDocumentPrivacyRequired: documentResponse.data.documentCombinations.isDocumentPrivacyRequired,
                        preferredDocuments: documentResponse.data.documentCombinations.preferredDocuments,
                        nonPreferredDocuments: documentResponse.data.documentCombinations.nonPreferredDocuments,
                        category: documentResponse.data.documentCombinations.category
                    },
                    isLoading: false,
                });
                
                userDispatch({ type: INCREMENT_SKIP_PAGE });
    
            } else {
                userDispatch({ type: SET_PROMPTS, prompts: promptsResponse.data, isLoading: false });
                userDispatch({ type: INCREMENT_INDEX });
            }
        } catch (error) {
            if (error.response) {
                // Session expired
                if (error.response.status === 403) {
                    userDispatch({ type: SET_SESSION_EXPIRED, isSessionExpired: true });
                }
    
                setUpdateError(error.response.data.message);
            } else {
                setUpdateError(t('errors.update-error'));
            }
    
            userDispatch({ type: SET_LOADING, isLoading: false });
        }
    };
    

    return (
        <form>
            <p className="gov__step-title">{t('shared.step-one')}</p>

            <InputText
                error={firstNamesError}
                label={t('biometric-form.first-name.label')}
                placeholder={t('biometric-form.first-name.placeholder')}
                onChangeHandler={(event) => handleFirstNameInput(event.target.value)}
                value={firstNames}
            />

            <InputText
                error={lastNameError}
                label={t('biometric-form.last-name.label')}
                placeholder={t('biometric-form.last-name.placeholder')}
                onChangeHandler={(event) => handleLastNameInput(event.target.value)}
                value={lastName}
            />

            <InputDropdown
                citizenship={selectedCitizenship}
                countries={userState.countries}
                label={t('biometric-form.citizenship.label')}
                onChangeHandler={(event) => setSelectedCitizenship(event.target.value)}
            />
            { updateError && <p className="gov__form-error">{updateError}</p> }

            <div className="gov__form-group">
                <Button isDisabled={!isFormValid()} onClickHandler={handleFormSubmit} text={t('shared.continue')} />
            </div>
        </form>
    );
};

export default BiometricsCapture;
