/**
 * DocumentCapture Component
 *
 * After a user selects their document combination they will begin to capture the required documents (or input code)
 * and a selfie. Since the Onfido SDK can only capture one document at a time, we keep a record of which document we
 *  are currently at via the documentIndex variable.
 *
 * E.g. If I select the document combination Passport & Birth Certificate, the process with begin with the documentIndex
 * at 0, which will point to the Passport in our selectedDocumentCombination array. The details of this document are
 * passed to the VendorSDK component. When the Onfido SDK flow is complete for that first document, the index will be
 * incremented, and we will move on to the next document (if there is any).
 *
 * Each time the value of documentIndex changes, the useEffect will re-run, checking the value against the length of the
 * selectedDocumentCombinations array. If they match, this signifies that we're finished with the document capture segment
 * and will run the checkNewHireStatus which starts a loading screen and a timeout for 10 seconds. Once the ten seconds are
 * up, we send a request to Salesforce to check what the status of the Right to Work check is. Based on that response, we show
 * the appropriate results screen.
 */

import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import supportedDocumentsByCountry from './onfido-supported-documents.json';

// Context
import UserContext from '../../state-management/context/UserContext';
import {
  SET_LOADING,
  SET_LOADING_MESSAGE,
  UPDATE_DOCUMENT_INDEX,
  UPDATE_INDEX_RESULTS,
} from '../../state-management/actions/actionTypes';

// Components
import CodeInput from './CodeInput';
import VendorSdk from './VendorSdk';

// Consts
import api from '../../consts/api';
import results from '../../consts/results';
import { COMPLETED, PENDING_F2F } from '../../consts/salesforceStatus';
import moment from 'moment';
import DocumentUpload from '../document-upload/DocumentUpload';
import { addSpacesToTitle } from '../../utils/stringManipulation';

const DocumentCapture = () => {
  // Context
  const [userState, userDispatch] = useContext(UserContext);

  // Translation hook
  const { t } = useTranslation();

  // State
  let documentIndex = userState.documentIndex;
  const [error, setError] = useState('');
  let onfidoFlow = [];

  /**
   * Keep track of users flow through the vendor SDK
   */
  addEventListener('userAnalyticsEvent', (event) => {
    const m = moment(Date.now());
    onfidoFlow.push(
      event.detail.eventName + ' | ' + m.format('YYYY-MM-DD:HH-mm-ss')
    );
  });

  /**
   * Check the documentIndex against the document combinations array length
   */
  /* useEffect(() => {
    console.log('useEffect', {
      documents: userState.selectedDocumentCombination.documents,
      documentIndex,
      currentDocument,
    });
    if (
      20 < 0 &&
      documentIndex === userState.selectedDocumentCombination.documents.length
    ) {
      console.log('documentCombinationDone');
      userDispatch({ type: SET_LOADING, isLoading: true });
      checkNewHireStatus();
    }
    // eslint-disable-next-line
  }, [documentIndex]); */

  /**
   * Function dedicated to incrementing the documentIndex. This function is passed to VendorSDK
   * and CodeInput to update the index.
   */
  const updateDocumentIndex = () => {
    const newDocumentIndex = userState.documentIndex + 1;
    if (newDocumentIndex == userState.selectedDocumentCombination.documents.length) {
      userDispatch({ type: SET_LOADING, isLoading: true });
      checkNewHireStatus();
    } else {
      userDispatch({
        type: UPDATE_DOCUMENT_INDEX,
        documentIndex: newDocumentIndex,
      });
    }
  };

  /**
   * Set a timeout for 10 seconds to check the status of the new hire's check, then forward the new hire to a results screen
   */
  const checkNewHireStatus = () => {
    userDispatch({
      type: SET_LOADING_MESSAGE,
      loadingMessage: t('document-capture.loading.processing'),
    });

    setTimeout(() => {
      userDispatch({
        type: SET_LOADING_MESSAGE,
        loadingMessage: t('document-capture.loading.nearly-there'),
      });
    }, 5000);

    setTimeout(() => {
      userDispatch({ type: SET_LOADING_MESSAGE, loadingMessage: '' });

      api
        .post('/new-hires/check', { onfidoApplicantId: userState.vendor.id })
        .then(() => {
          api
            .get(`/new-hires/verify`)
            .then((resultVerificationResponse) => {
              // Log success for monitoring
              const timeNow = moment();
              const timeDifference = timeNow.diff(userState.startTime);
              api.post(`/logging/success`, {
                uuid: userState.uuid,
                duration: moment(timeDifference).format('mm:ss'),
              });
              renderResultsScreen(resultVerificationResponse);
            })
            .catch((error) => {
              api.post(`/logging/error`, {
                message: 'Error caught from /new-hire/verify endpoint',
                error: error,
                uuid: userState.uuid,
              });
              setError(t('document-capture.error.server-error'));
            });
        })
        .catch((error) => {
          api.post(`/logging/error`, {
            message: 'Error caught from /new-hire/check endpoint',
            error: error,
            uuid: userState.uuid,
          });
          setError(t('document-capture.error.server-error'));
        });
    }, 10000);
  };

  /**
   * Based on the value returned from Salesforce, choose which results screen to render
   *
   * @param {any} resultVerificationResponse result returned from Salesforce
   */
  const renderResultsScreen = (resultVerificationResponse) => {
    switch (resultVerificationResponse.data.status) {
      case COMPLETED:
        userDispatch({
          type: UPDATE_INDEX_RESULTS,
          result: results.SUCCESS,
        });

        break;
      case PENDING_F2F:
        userDispatch({
          type: UPDATE_INDEX_RESULTS,
          result: results.PENDING_F2F_CHECK,
        });

        break;
      default:
        userDispatch({
          type: UPDATE_INDEX_RESULTS,
          result: results.PENDING,
        });
    }
  };

  /**
   * Check if Onfido Document is Valid
   */
  const getOnfidoDocumentType = (salesforceDocumentType) => {
    let isValidDocument;
    const supportedDocumentsForCountryOfHire =
      supportedDocumentsByCountry[userState.user.isoCountryOfHire];
    isValidDocument = supportedDocumentsForCountryOfHire.includes(
      salesforceDocumentType
    );

    // If doc type doesn't fit one of the SDK's accepted types reject also
    if (
      salesforceDocumentType.toLowerCase() !== 'passport' &&
      salesforceDocumentType.toLowerCase() !== 'driving_licence' &&
      salesforceDocumentType.toLowerCase() !== 'national_identity_card' &&
      salesforceDocumentType.toLowerCase() !== 'residence_permit'
    ) {
      isValidDocument = false;
    }

    return isValidDocument
      ? { [salesforceDocumentType]: true }
      : {
          unknown: true,
          documentType: salesforceDocumentType,
          documentSides: currentDocument.sides,
        };
  };

  if (error) {
    return (
      <div className="gov__document-capture-container">
        <p>{error}</p>
      </div>
    );
  }

  if (!(userState.vendor.id && userState.vendor.token)) {
    return (
      <div className="gov__document-capture-container">
        <p>{t('document-capture.error.vendor-error')}</p>
      </div>
    );
  }

  // Grab the current document
  const currentDocument =
    {...userState.selectedDocumentCombination.documents[documentIndex], name: addSpacesToTitle(userState.selectedDocumentCombination.documents[documentIndex].name, '/') }
  const renderDocumentCaptureType = () => {
    if (!currentDocument.supported && currentDocument.type !== 'share_code') {
      return (
        <DocumentUpload
          document={currentDocument}
          updateDocumentIndex={updateDocumentIndex}
        />
      );
    } else if (
      currentDocument.supported &&
      currentDocument.type !== 'share_code'
    ) {
      return (
        <VendorSdk
          key={currentDocument.id}
          isSelfieRequired={documentIndex === 0} // only ask for a selfie on the first document
          onfidoApplicantId={userState.vendor.id}
          onfidoDocumentType={getOnfidoDocumentType(currentDocument.type.toLowerCase())}
          onfidoToken={userState.vendor.token}
          salesforceDocument={currentDocument}
          setError={setError}
          updateDocumentIndex={updateDocumentIndex}
        />
      );
    } else if (currentDocument.type === 'share_code') {
      return (
        <CodeInput
          document={currentDocument}
          updateDocumentIndex={updateDocumentIndex}
          onfidoApplicantId={userState.vendor.id}
        />
      );
    } else {
      console.log('Incorrect documents options configured');
      return <h1>An error occurred</h1>;
    }
  };

  return (
    <div className="gov__document-capture-container">
      {!!currentDocument && renderDocumentCaptureType()}
    </div>
  );
};

export default DocumentCapture;
